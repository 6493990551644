.terms-condition h3 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  padding-bottom: 8px;
}

.terms-condition h4 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  padding-bottom: 8px;
}

.terms-condition p {
  font-size: 18px;
  color: #000;
  font-size: 16px;
  padding-bottom: 22px;
  text-align: justify;
}
