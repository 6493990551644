.daily-katha-videos {
  box-shadow: none !important;
  border: 1px solid var(--global-color);
  cursor: pointer;
  border-radius: 0px !important;
  position: relative;
  width: 95%;
  padding: 10px;
}
.daily-katha-video-wrap {
  margin: 0 auto;
}

.daily-katha-videos h4 {
  text-align: center !important;
  color: #08416b;
  align-items: center;
  font-size: 16px !important;
  font-weight: bold;
  padding-top: 12px;
}
.date-picker-wrap h4 {
  color: #08416b;
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 18px !important;
  font-weight: bold;
}

.daily-katha h6 {
  font-size: 22px;
  font-weight: bold;
  color: var(--text-color);
  text-transform: uppercase;
}
iframe.katha-video {
  width: 50%;
  height: 336px;
}
.daily-katha h5 {
  color: #777 !important;
}

.katha-video {
  border: 1px solid var(--global-color);
  padding: 10px;
  /* height: 100% !important; */
}

.date-picker-img {
  width: 35px;
}
.daily-katha {
  padding-top: 80px;
}

/* iframe.katha-video {
  height: auto;
} */

/* .katha-date-picker input {
  padding: 10px;
} */

@media (min-width: 576px) and (max-width: 991.98px) {
  /* .daily-katha {
    padding-top: 82px !important;
  } */
}

@media (max-width: 1199.98px) {
  .date-picker-daily-darshan {
    padding: 0px 0px 0px 0px;
  }
  .date-picker-wrap h4 {
    font-size: 20px !important;
  }
}

@media (max-width: 991.98px) {
  .daily-katha {
    padding-top: 55px;
  }
  .date-picker-daily-darshan {
    padding: 0px 0px 0px 0px;
  }
  .date-picker-wrap h4 {
    font-size: 20px !important;
  }
  .date-picker-img {
    width: 35px !important;
  }
  /* .date-picker {
    padding-top: 34px;
  } */
  iframe.katha-video {
    height: 300px;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .daily-katha {
    /* padding-top: 55px; */
    padding-top: 80px;
  }
  .date-picker-daily-darshan {
    padding: 0px 0px 0px 0px;
  }
  .date-picker-wrap h4 {
    font-size: 18px !important;
  }
  .date-picker-img {
    width: 30px !important;
  }
  .daily-katha h6 {
    font-size: 18px;
  }
  /* .date-picker {
    padding-top: 34px;
  } */
  .katha-date-picker input {
    padding: 10px;
  }
}

@media (max-width: 575.98px) {
  .daily-katha {
    padding-top: 80px;
  }

  .date-picker-img {
    width: 20px !important;
  }
  .daily-katha h6 {
    font-size: 14px;
  }

  .date-picker-wrap h4 {
    font-size: 14px !important;
  }
  .darshan-details p {
    font-size: 14px;
  }
  /* .date-picker {
    padding-top: 34px;
  } */
  .date-picker {
    flex-direction: column;
    align-items: start;
  }
  .katha-date-picker input {
    padding: 10px;
  }
}
@media (min-width: 200px) and (max-width: 400px) {
  iframe.katha-video {
    height: 143px;
    width: 100%;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  iframe.katha-video {
    height: 176px;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  iframe.katha-video {
    height: 200px;
    width: 100%;
  }
}
