.tabs {
    display: flex;
    align-items: center;
    border-radius: 2px;
    background-color: #11152417;
    border: 1px solid var(--text-color);
    border-radius: 2px;
    position: relative;
    z-index: 1;
    gap: 45px;

    &:has(:focus-visible) {
        outline: 3px solid rgb(17 21 36 / 50%);
    }
}

.tabs-marker {
    position: absolute;
    z-index: -1;
    background-color: var(--text-color);
    color: #fff !important;
    top: 0.4rem;
    bottom: 0.4rem;
    left: 0;
    border-radius: 2px;
    transition: 0.15s;
}

.tabs-tab {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    transition: all 150ms;
    outline-width: 2px;
    outline-offset: 2px;
    /* padding: 0px; */
    border-width: 0;
    color: inherit;
    font-weight: 500;
    padding: 1rem;
    /* color: white; */
    color: var(--text-color) !important;
    background: transparent;
    cursor: pointer;
    outline: none;
    font-family: inherit;
    font-size: 0.95rem;
    padding: 20px 50px 20px 50px;
}

.tabs-tab {
    color: var(--text-color) !important;
}

.tabs-tab.active-tab {
    color: white !important;
}

