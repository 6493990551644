.Custom-Card {
  padding: 24px;
  max-height: 500px;
  overflow: auto !important;
}
.Custom-Card h4 {
  font-size: 30px;
  color: #08416b;
  font-weight: 700;
  text-align: center;
}
.custom-page-content {
  padding-top: 80px;
}
.custom-page-content h4 {
  font-size: 23px;
  font-weight: 700;
  color: #08416b !important;
}
.custom-page-div {
  padding: 20px !important;
}
.custom-image {
  height: auto;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
@media (min-width: 767.98px) and (max-width: 1024px) {
  .custom-page-content {
    margin-top: 53px !important;
  }
}
@media (min-width: 200px) and (max-width: 576px) {
  .custom-page-content {
    margin-top: 53px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .custom-page-content {
    margin-top: 53px !important;
  }
}
