.activities-content p {
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
}

.activities-content {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 20px;
}
.activities-content h4 {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #08416b;
  font-size: 18px !important;
  font-weight: 700;
}

.activities-content .GrFormNextLink {
  flex-shrink: 0;
  margin-left: 10px;
}

.activity-img.home {
  width: 100%;
  transition: transform 0.3s ease-in-out;
  height: 200px;
  object-fit: fill;
}

.activities-img {
  width: 100%;
  transition: transform 0.3s ease-in-out;
  height: 200px;
  object-fit: fill;
}

.activity-img.home:hover {
  transform: scale(1.1);
}

.activities-img:hover {
  transform: scale(1.1);
}

.activities-img-thumbnail {
  /* width: 100%; */
  width: 235px;
  transition: transform 0.3s ease-in-out;
  height: 170px;
  object-fit: fill;
}

.activities-img-thumbnail:hover {
  transform: scale(1.1);
}

.activities-name {
  color: var(--text-color);
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  text-align: center;
}

.activities-content-wrap {
  box-shadow: none !important;
  border: 1px solid brown;
  cursor: pointer;
  border-radius: 0px !important;
  /* height: 290px; */
  height: 265px;
}

.activities-name-wrap {
  position: absolute;
  width: 80%;
  background-color: #fff;
  bottom: -35px;
  padding: 20px;
  z-index: 999;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: space-around;
}

.activities-name-wrap h6 {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 900px) and (max-width: 1024px) {
  .activity-img.home {
    height: 165px;
  }
  .activities-content-wrap {
    height: 230px;
  }
}

@media (max-width: 767px) {
  .activities-content-wrap {
    height: auto;
  }
}

@media (max-width: 576px) {
  .activities-content-wrap {
    height: auto;
  }
  .activity-img.home {
    /* height: 160px; */
    /* height: 145px; */
    height: 130px;
  }
  .activities-content h4 {
    font-size: 16px !important;
  }
}
