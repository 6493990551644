.header-wrap {
  background-color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  padding-right: 0px;
  overflow-y: auto;
}

.header-wrap h6 {
  cursor: pointer;
  font-size: 12px;
  padding: 0px 10px 0px 10px;
}

/* .header-wrap div:not(:last-child) {
  border-right: 1px solid #bcd0f9;
} */

.header-wrap div {
  padding: 5px;
  padding-left: 7px;
  padding-right: 2px;
  align-items: center;
}

.header-left div:not(:last-child) {
  border-right: 1px solid #bcd0f9;
}

/* .header-left div {
  border-right: 1px solid #bcd0f9;
} */

/* .header-right div:not(:last-child) {
  border-right: 1px solid #bcd0f9;
} */

.header-right div:not(:last-child):not(.no-border) {
  border-right: 1px solid #bcd0f9;
}

@media (min-width: 359.99px) and (max-width: 767px) {
  .header-wrap {
    justify-content: center;
    padding-right: 0px;
  }
  .header-wrap div {
    padding: 4px;
    width: max-content;
  }
  .header-wrap h6 {
    font-size: 10px;
  }
}

@media (min-width: 768px) and (max-width: 992.98px) {
  .header-wrap h6 {
    font-size: 11px;
  }
}
