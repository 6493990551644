.katha-content-wrap {
  padding: 30px 0px 0px 0px;
}

/* .katha-img-wrap {
  border: 1px solid var(--global-color);
  padding: 10px;
  border-radius: 2px;
} */

.katha-img-wrap button {
  border: 0px;
  font-size: 14px;
  padding: 10px 15px 10px 15px;
  border: 1px solid var(--text-color);
  background-color: var(--text-color);
  color: #fff;
  border-radius: 2px;
  letter-spacing: 0.5px;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
}
.katha-content-publication {
  gap: 6px !important;
  padding: 0 !important;
}

.katha-img-wrap button:hover {
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid var(--text-color);
}

.katha-img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.video-album-img {
  width: 100%;
  /* height: 250px; */
  height: 160px;
  object-fit: contain;
}

.video-albums-image {
  width: 100%;
  /* height: 250px; */
  height: 160px;
  object-fit: contain;
}

.katha-albums-image {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.katha-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0px 0px 0px;
  gap: 10px;
  /* overflow: hidden; */
}

.katha-content h4 {
  color: var(--text-color);
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  max-width: 90%; */
}

.katha-content-wrap p {
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
}

.book-details {
  letter-spacing: 0.5px;
}

.book-author-name {
  color: var(--text-color);
  letter-spacing: 0.5px;
}

.video-album-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 5px 0px 0px 0px;
}

@media (max-width: 767px) {
  .video-album-img {
    height: auto;
  }
}

@media (max-width: 576px) {
  .katha-img-wrap {
    padding: 10px;
  }
  .video-album-img {
    height: auto;
  }
  .katha-img {
    height: auto;
  }
}
