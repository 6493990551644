/* ========== Navbar ========== */

.navbar-nav {
  display: flex;
}

.navbar {
  height: 80px;
  background-color: #fff;
  padding: 0px 10px 0px 10px;
}

.navbar-right-wrap {
  display: flex;
  align-items: center;
}

li.nav-item {
  margin-top: 14px;
}

.nav-text h6 {
  color: var(--global-color);
  font-weight: 600;
  font-size: 20px;
  max-width: 265px;
  word-wrap: break-word;
}

.navbar-brand {
  padding: 0px;
}

.nav-icon {
  margin-top: -3px;
}

.navbar-logo {
  /* width: 80px; */
  /* height: 80px; */
  width: 350px;
  height: 50px;
  object-fit: contain;
  /* border-right: 1px solid #efefef; */
}

.navbar-leftside {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  text-transform: uppercase;
  color: #b5090e;
  font-weight: 600;
}

.navbar-leftside li {
  padding-right: 10px;
}

.navbar-leftside li:hover {
  cursor: pointer;
}

/* .navbar-nav .nav-link.active-link {
  color: inherit !important;
} */

.navbar-leftside li:not(:last-child) {
  /* border-right: 1px solid #413d3d; */
  border-right: 1px solid #08416b;
}

.navbar-nav .nav-link {
  text-transform: uppercase;
}

.navbar-nav .nav-link:hover {
  color: #4a4a49;
}

.toggler-icon {
  color: #b5090e;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.dropdown-menu {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 0;
    width: 200px;
    padding: 10px 10px 10px 10px; */
  background-color: #fff;
  min-width: 230px;
  box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  border: 0px;
  /* margin-top: px; */
  border-radius: 0;
}

.nav-link.donation {
  background-color: var(--text-color);
  color: #fff;
  cursor: pointer;
  border: 1px solid var(--text-color);
  border-radius: 2px;
  text-align: center;
  width: 125px;
  height: 42px;
  /* width: 140px;
    height: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 10px; */
  border-radius: 30px;
  transition: all 300ms ease;
}

.nav-link.donation:hover {
  color: var(--text-color) !important;
  border: 1px solid var(--text-color) !important;
  background-color: transparent !important;
}

.dropdown-menu .dropdown-item:hover {
  background-color: transparent !important;
  color: #b5090e;
  transform: translateX(5px);
  transition: 0.5s;
}

.dropdown-menu li a {
  /* font-size: 14px;
    color: #4a4a49;
    padding: 10px; */
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  color: #777;
  font-weight: 400;
  text-transform: uppercase;
}

.dropdown-menu li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  color: #777;
  font-weight: 400;
  text-transform: uppercase;
}

.navbar-nav {
  flex-direction: row;
  gap: 10px;
}

.navbar-nav .dropdown-menu {
  position: unset !important;
}

.nav-link-wrap {
  list-style: none;
  display: flex;
  margin-right: auto;
  gap: 15px;
}

.navbar-toggler {
  border: 0px;
  color: #b5090e;
}

.navbar-wrap {
  display: flex;
  align-items: center;
}

.nav-elements ul a {
  font-size: 13px;
  font-weight: 600;
  color: var(--global-color);
  text-decoration: none;
  text-transform: uppercase;
}

/* .nav-elements a.active {
  color: var(--text-color);
  font-weight: 600;
} */

.nav-link.active {
  color: var(--text-color) !important;
}

.nav-link.no-active {
  color: var(--global-color) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
  color: var(--global-color);
}

/* .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--text-color);
} */

@media (max-width: 767.98px) {
  .navbar-text1 {
    display: none;
  }

  .navbar-wrap {
    display: none;
  }

  .navbar-logo {
    width: 225px;
  }
}

@media (max-width: 992.98px) {
  .navbar-toggler {
    font-size: 25px;
  }

  .navbar-text1 {
    display: none;
  }

  .navbar-wrap {
    display: none;
  }

  .navbar-logo {
    width: 245px;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
}

@media (min-width: 993px) and (max-width: 1024.98px) {
  .navbar-wrap {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-wrap {
    display: none;
  }
}

@media (max-width: 1199.99px) {
  .navbar-logo {
    height: 38px;
  }

  .navbar {
    padding: 0px;
  }

  .nav-link.donation.responsive {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50px;
    margin-left: 0px;
    padding: 14px;
  }

  .offcanvas-body {
    padding: 0px 5px 0px 5px;
  }

  .nav-link-wrap.mobile {
    display: block;
  }

  .nav-link-wrap ul li {
    display: flex;
  }

  .offcanvas-body .navbar-nav {
    flex-direction: column;
  }

  .navbar-nav .nav-item .nav-link {
    /* padding: 10px 15px; */
    /* padding: 10px 0px 15px 0px; */
    /* padding: 10px 0px 15px 12px; */
    padding: 15px 0px 20px 12px;
  }

  .navbar-nav .nav-item:not(:last-child) .nav-link {
    border-bottom: 1px solid #ccc;
  }

  .offcanvas-body .navbar-nav .nav-item .dropdown-menu {
    background-color: #f7f7f7;
    width: auto;
    box-shadow: none;
  }

  .offcanvas-body .navbar-nav .nav-item .dropdown-menu {
    padding: 0px;
  }

  .offcanvas-header .navbar-logo {
    border-right: 0px;
  }

  .offcanvas-body .navbar-nav .nav-item .dropdown-menu li a {
    color: #b5090e;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    padding-left: 10px;
  }

  .offcanvas-body .navbar-nav .nav-item .dropdown-menu li:not(:last-child) a {
    border-bottom: 1px solid #0000001a;
  }

  li.nav-item {
    margin-top: 0px;
  }

  .offcanvas-header {
    margin-bottom: 20px;
  }

  .navbar-logo {
    width: 245px;
  }
}

@media (max-width: 576.98px) {
  .nav-text h6 {
    max-width: 175px;
  }

  .navbar-text1 {
    display: none;
  }

  .navbar-wrap {
    display: none;
  }

  .navbar-logo {
    height: 28px;
    width: 200px;
  }

  .navbar-toggler {
    font-size: 22px;
  }
}


@media (max-width: 390px) {
  .navbar-logo {
    height: 25px;
  }

  .navbar-toggler {
    font-size: 20px;
  }
}