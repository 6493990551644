.about-img {
  width: 100%;
  height: 250px;
  /* height: 340px; */
}

.publication-tabs {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 10px;
}

.bbb {
  padding: 0px 25px 0px 25px;
}

.tabs {
  display: flex;
  align-items: center;
  border-radius: 2px;
  background-color: #11152417;
  border: 1px solid var(--text-color);
  border-radius: 2px;
  position: relative;
  z-index: 1;
  gap: 45px;
  overflow-x: auto;

  &:has(:focus-visible) {
    outline: 3px solid rgb(17 21 36 / 50%);
  }
}

.tabs-marker {
  position: absolute;
  z-index: -1;
  background-color: var(--text-color);
  color: #fff !important;
  top: 0.4rem;
  bottom: 0.4rem;
  left: 0;
  border-radius: 2px;
  transition: 0.15s;
}

.tabs-tab {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  transition: all 150ms;
  outline-width: 2px;
  outline-offset: 2px;
  border-width: 0;
  color: inherit;
  font-weight: 500;
  padding: 1rem;
  color: var(--text-color) !important;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-family: inherit;
  font-size: 0.95rem;
  padding: 20px 50px 20px 50px;
  flex: 1;
  max-width: 150px;
}

.tabs-tab {
  color: var(--text-color) !important;
  flex: 1;
  max-width: 150px;
}

.tabs-tab.active-tab {
  color: white !important;
}

.publication-tabs button {
  background-color: transparent;
  color: var(--text-color);
  border: 0;
  padding: 13px 27px 13px 27px;
  border-radius: 2px;
  font-size: 15px;
  letter-spacing: 0.5px;
  transition: background-color 0.3s, color 0.3s;
  text-transform: capitalize;
}

.publication-tabs button.active-tab {
  color: #fff;
  background-color: var(--text-color);
  /*  */
  height: 39px;
}

.publication-dropdown {
  min-width: 220px !important;
  height: 45px;
  border: none;
  box-shadow: none;
}

.publication-serach-wrap {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
  gap: 24px;
  /* padding-top: 50px; */
}

.publication-search-input-wrap {
  width: 200px;
}
.search-button {
  border: 0px;
  border-top: 1px solid var(--text-color);
  border-right: 1px solid var(--text-color);
  border-bottom: 1px solid var(--text-color);
  padding: 12px;
}
.search-icon-publication {
  color: var(--text-color);
  background-color: transparent;
}
.form-outline-search input {
  border: 1px solid var(--text-color);
  width: 545px !important;
  padding: 12px;
}
.publication-serach-inptut-serch-icon {
  /* border-radius: 0px; */
  border-radius: 5px 0px 0px 5px;
  border-right: 0;
  padding: 20px;
}

.publication-serach-inptut {
  /* border-radius: 0px; */
  border-radius: 0px 12px 12px 0px;
  border-left: 0;
}
.publication-serach-inputfield {
  border-left: 0px;
  /* border-right: 0px; */
  border: 1px solid var(--text-color);
  padding: 16px;
}
.publication-serach-inputfield:focus {
  border: 1px solid #ccc !important;
}
.modal-search-input {
  border-right: 0px;
  border-radius: 2px 0px 0px 2px;
}
.publication-modal-input {
  margin-top: 15px;
}
.publication-modal-text {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  padding: 18px 0px 0px 0px;
}
.publication-modal-content {
  border: 1px solid var(--text-color);
  background-color: #cccccc42;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  color: var(--text-color);
  font-size: 15px;
  padding: 15px 0px 15px 15px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.2s;
}
.publication-modal-content:hover {
  color: #fff;
  background-color: var(--text-color);
}

.publication-tab-wrap {
  display: flex;
  gap: 10px;
  border: 1px solid var(--text-color);
  padding: 6px;
  margin-left: auto;
  margin-right: auto;
  /*  */
  padding: 3px 2px 2px 2px;
  border-radius: 2px;
  height: 46px;
}
.modal-content.publication {
  direction: ltr;
  scrollbar-color: #e4e4e4;
  scrollbar-width: thin;
}

/* .modal-content.publication::-webkit-scrollbar {
  width: 10px;
}
.modal-content.publication::-webkit-scrollbar-track {
  background-color: #e4e4e4;

  border-radius: 8px;
}

.modal-content.publication::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #ccc;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
} */

/* .input-group {
  width: 100%;
} */

.input-group-prepend,
.input-group-text {
  display: flex;
  align-items: center;
}

.publication-search-input-wrap {
  max-width: 400px; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the input */
}

.publication-search-input-field {
  border-radius: 0;
}
.search-input-wrap {
  position: relative;
}

.search-input-publicationlist {
  /* width: 100%;
  padding: 10px;
  border: 1px solid var(--text-color); */
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 2px solid var(--text-color);
  box-sizing: border-box;
  border-radius: 4px;
}

.modal-search-icon {
  /* position: absolute;
  top: 0px; */
}

.search-icon-publication-modal {
  color: var(--text-color);
  background-color: transparent;
  position: absolute;
  top: 21px;
  right: 27%;
}

.search-input-publicationlist-modal {
  width: 100%;
  padding: 20px 10px 20px 40px;
  border: 1px solid var(--text-color);
  box-sizing: border-box;
}

.search_input {
  /* width: 75%; */
  /* width: 130px; */
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--text-color);
  border-radius: 2px;
  font-size: 16px;
  background-color: white;
  background-image: url("../../../assets/images/magnifying-glass\ \(12\).png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 18px 20px 18px 40px;
  height: 45px;
}

/* .search_input_activity {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--text-color);
  border-radius: 2px;
  font-size: 16px;
  background-color: white;
  background-image: url("../../../assets/images/magnifying-glass\ \(12\).png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 18px 20px 18px 40px;
  height: 45px;
} */

.search-text {
  position: absolute;
  top: 22px;
  left: 40px;
  color: #777;
}

.publication-modal-search {
  position: relative;
}

.search-icon-wrap {
  /* position: absolute;
  top: 60px;
  left: 20px; */
  position: absolute;
  top: 59px;
  left: 30px;
  /* transform: translateY(-50%); */
}

.loader-container {
  background-color: rgba(0, 0, 0, 0.5);
  /* / Black with 50% opacity / */
  /* / White with 50% opacity / */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

/* @media (max-width: 992px) {
  .tabs-tab {
    max-width: 100%;
  }

  .publication-tabs {
    flex-direction: column;
    align-items: normal;
  }

  .publication-dropdown {
    width: 100% !important;
  }
  .publication-tabs {
    padding-top: 37px;
  }

  .publication-tab-wrap {
    flex-direction: column;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .publication-serach-wrap {
    align-items: center;
    flex-direction: column;
  }
  .publication-search-input-wrap {
    width: 100%;
  }
} */
.publication-wrap {
  padding-top: 60px;
}
.publication-filter-wrap {
  /* width: 100%; */
}
.publication-filter {
  width: 100%;
}
.publication-filter-dropdown {
  /* width: 100%; */
  width: 220px;
  height: 45px;
}
.search-input {
  height: 45px;
}
.publication_search_wrap {
  /* width: 100%; */
  /* width: 50%; */
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon1 {
  position: absolute;
  left: 10px;
  color: var(--text-color);
  pointer-events: none;
}

.search-input {
  padding-left: 40px;
  width: 100%;
  box-sizing: border-box;
  height: 45px;
  border: 1px solid var(--text-color);
  border-radius: 2px;
  /* padding: 10px 10px 10px 40px; */
  /* box-sizing: border-box; */
}

.search-input:focus {
  border-color: #aaa;
  outline: none;
}

@media (max-width: 992px) {
  .publication-tabs {
    flex-direction: column;
  }

  .publication-tab-wrap {
    flex-direction: column;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    padding: 10px 12px 12px 12px;
  }

  .publication-tabs button {
    width: 100%;
  }

  .tabs-marker {
    max-width: 100%;
    height: 20px;
  }

  .publication-tabs button {
    border: 1px solid var(--text-color) !important;
  }

  .publication-dropdown {
    width: 100% !important;
  }
  .about-img {
    height: auto;
    object-fit: contain;
  }
  .publication-tabs {
    padding-top: 37px;
  }
  .publication-serach-wrap {
    align-items: normal;
    flex-direction: column;
    gap: 14px;
  }
  .publication-search-input-wrap {
    width: 100%;
  }
  .search_input {
    width: 100%;
  }
  .publication-wrap {
    padding-top: 0px;
  }
  .publication-filter-dropdown {
    width: 100%;
  }
  .publication-tabs button.active-tab {
    height: 44px;
  }
}

@media (max-width: 767.98px) {
  .about-img {
    height: auto;
    object-fit: contain;
  }
  .publication-tabs {
    padding-top: 37px;
  }
  .publication-serach-wrap {
    align-items: normal;
    flex-direction: column;
    gap: 12px;
  }
  .publication-search-input-wrap {
    width: 100%;
  }
  .form-outline-search input {
    /* width: auto !important; */
  }
  .publication-modal-input {
    justify-content: center;
  }
  .modal-content.publication {
    width: 92%;
    top: 20%;
    /* height: 400px; */
  }
  .search_input {
    width: 100%;
  }
  .publication-wrap {
    padding-top: 0px;
  }
  .publication-tabs button.active-tab {
    height: 38px;
  }
  .publication-tab-wrap {
    padding: 10px 12px 12px 12px;
  }
  .publication-tabs button {
    padding: 10px 27px 10px 27px;
    font-size: 12px;
  }
  .publication-filter-dropdown {
    height: 32px;
  }
  .search-input {
    height: 32px;
  }
  .search_input_activity {
    height: 32px;
    background-position: 10px 3px;
  }
  .search-container {
    width: 100%;
  }
}

@media (min-width: 577px) and (max-width: 682px) {
  .publication-search-input-wrap {
    width: 100%;
  }
  .form-outline-search input {
    width: auto !important;
  }
  .publication-modal-input {
    justify-content: center;
  }
  .modal-content.publication {
    width: 92%;
  }
}

@media (max-width: 576.98px) {
  .about-img {
    height: auto;
    object-fit: contain;
  }
  .publication-tabs {
    padding-top: 37px;
  }
  .publication-serach-wrap {
    align-items: normal;
    flex-direction: column;
    gap: 10px;
  }
  .publication-search-input-wrap {
    width: 100%;
  }
  .form-outline-search input {
    width: auto !important;
  }
  .publication-modal-input {
    justify-content: center;
  }
  .modal-content.publication {
    width: 92%;
  }
  .search_input {
    width: 100%;
    /* height: 50px; */
    height: 32px;
    background-position: 10px 3px;
  }
  .search-text {
    top: 8px;
  }
  .publication-tabs button {
    padding: 10px 27px 10px 27px;
    font-size: 12px;
  }
  .publication-wrap {
    padding-top: 0px;
  }
  .publication-filter-dropdown {
    width: 100%;
    height: 32px;
  }
  .search-input {
    height: 32px;
  }
  .publication-tabs button.active-tab {
    height: 36px;
  }
  .publication-tab-wrap {
    padding: 10px 12px 12px 12px;
  }
}
