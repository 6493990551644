body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: "Poppins", sans-serif;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: "Poppins", sans-serif;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-family: "Poppins", sans-serif;
  background-color: rgb(251, 251, 251);
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* =============== common css =============== */

:root {
  --main-background-color: #dad6d633;
  --global-color: #b5090e;
  --text-color: #08416b;
  --box-border-color: #08416b;
  --box-shadow-color: rgba(166, 15, 15, 0.592);
}

.section-padding {
  padding: 30px 0px;
}

.section-title {
  font-size: 46px;
  color: #b5090e;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 700;
}

h5 {
  color: #08416b;
  margin: 0;
  display: flex;
  /* justify-content: center; */
  position: relative;
  font-size: 18px !important;
  font-weight: bold;
}

p {
  color: #777;
  position: relative;
  /* margin: 20px 0; */
  font-size: 15px;
  /* padding: 10px 30px; */
  background-position: center;
  background-size: cover;
  line-height: 1.7;
}

.spinner-container {
  position: relative;
}

.spinner-container-banner {
  width: 100%;
  height: auto;
  /* height: 340px; */
  /* height: 250px; */
  position: relative;
  overflow: hidden;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

@media (max-width: 1400px) {
  .spinner-container-banner {
    height: auto;
  }
}

@media (max-width: 991px) {
  .section-padding {
    padding: 25px 0px;
    /* padding: 35px 0px; */
  }
  .spinner-container-banner {
    height: auto;
  }
}

@media (max-width: 768px) {
  .section-padding {
    padding: 25px 0px;
    /* padding: 40px 0px; */
  }

  .section-title {
    font-size: 30px;
  }

  .white {
    font-size: 30px !important;
  }

  .section-title.publication {
    font-size: 30px;
  }
  .spinner-container-banner {
    height: auto;
  }
  .scroll-to-top-arrow {
    right: 7px !important;
    font-size: 12px !important;
  }
}

@media (max-width: 576px) {
  .section-title {
    font-size: 20px;
  }

  .white {
    font-size: 20px !important;
  }

  .section-title.publication {
    font-size: 20px;
  }
  iframe {
    /* height: 200px; */
  }
  .section-padding {
    padding: 15px 0px;
  }
}

/* ========== ScrollBottomToTopArrow ========== */

.scroll-to-top-arrow {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  background-color: var(--text-color);
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  transition: opacity 0.3s;
  z-index: 999;
}

.scroll-to-top-arrow.visible {
  display: block;
}
@media (min-width: 200px) and (max-width: 400px) {
  iframe {
    /* height: 143px; */
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  iframe {
    /* height: 176px; */
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  iframe {
    /* height: 200px; */
  }
}
