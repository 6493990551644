.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.dialog-content {
  background: white;
  padding: 0px 30px 30px 30px;
  border-radius: 8px;
  /* max-width: 80%; */
  max-height: 80%;
  width: 50%;
  height: 500px;
  /* overflow: auto; */
}

.close-btn {
  float: right;
  padding: 10px 0px 10px 0px;
  font-size: 22px;
  cursor: pointer;
}

.dialog-video {
  width: 100%;
  height: 400px;
}

@media (max-width: 767px) {
  .dialog-content {
    width: 300px;
    height: 350px;
  }

  .dialog-video {
    height: 260px;
  }
}

@media (max-width: 576px) {
  .dialog-content {
    width: 300px;
    height: 350px;
  }

  .dialog-video {
    height: 260px;
  }
}
