.slider-img {
  width: 100%;
  /* height: 600px; */
  /* height: auto; */
  /* height: 400px; */
  z-index: 0;
  position: relative;
  object-fit: cover;
}

.custom-prev-arrow {
  position: absolute;
  z-index: 999;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--global-color);
  border-radius: 50%;
  /* padding: 15px; */
  padding: 10px 11px 10px 11px;
  color: #fff;
  cursor: pointer;
}

.custom-prev-icon {
  font-size: 14px;
}

.custom-next-icon {
  font-size: 14px;
}

.custom-next-arrow {
  position: absolute;
  z-index: 999;
  right: 20px;
  background-color: var(--global-color);
  border-radius: 50%;
  /* padding: 15px; */
  padding: 10px 11px 10px 11px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
}

.slider-wrap {
  padding: 0;
  overflow: hidden;
}

.slider-buttons {
  height: auto;
}
.lazy-load-container {
  /* height: auto; */
}
/* ================================================ */

.content {
  display: none;
}

.all-loaded .content {
  display: block;
}

@media (max-width: 992px) {
  .custom-prev-arrow {
    padding: 8px 10px 8px 10px;
  }

  .custom-prev-icon {
    font-size: 12px;
  }

  .custom-next-icon {
    font-size: 12px;
  }

  .custom-next-arrow {
    padding: 8px 10px 8px 10px;
  }
}

@media (max-width: 767.98px) {
  /* .slider-img {
    height: auto;
    height: 300px;
    object-fit: cover;
  } */

  .custom-prev-arrow {
    /* padding: 10px; */
    padding: 8px 10px 8px 10px;
  }

  .custom-prev-icon {
    font-size: 12px;
  }

  .custom-next-icon {
    font-size: 12px;
  }

  .custom-next-arrow {
    /* padding: 10px; */
    padding: 8px 10px 8px 10px;
  }
}

@media (max-width: 576.98px) {
  .custom-next-arrow {
    padding: 6px 9px 6px 9px;
  }

  .custom-next-icon {
    font-size: 10px;
  }

  .custom-prev-arrow {
    padding: 6px 9px 6px 9px;
  }

  .custom-prev-icon {
    font-size: 10px;
  }
}
