.video-content-text {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon-wrap {
  font-size: 20px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
  margin-right: 10px;
  color: #08416b;
  background-color: #e6e8e7;
}

.video-inner-content-wrap .video {
  width: 100%;
  height: 315px;
}

.video-inner-content-wrap p {
  margin: 0px;
}

.video-inner-content {
  display: flex;
  gap: 20px;
  margin: 40px 0;
}

.video-content-wrap {
  display: flex;
  gap: 60px;
}

.video-inner-content-wrap {
  width: 50%;
}

.content {
  color: #777;
  position: relative;
  font-size: 15px;
  padding: 10px 30px;
  background-position: center;
  background-size: cover;
  line-height: 1.7;
  border-left: 2px solid #08416b;
}

.content:before {
  content: "";
  position: absolute;
  left: -9px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 30px;
  background-color: #08416b;
}

.content:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 30px;
  background-color: #08416b;
}

.video-content {
  display: flex;
  /* flex-direction: column; */
  gap: 14px;
  flex-wrap: wrap;
  width: 520px;
}
.spinner-container {
  position: relative;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.live-video-content {
  padding: 15px;
  /* min-height: 315px; */
}
.live-katha-video {
  min-height: 315px;
}

@media (max-width: 1199.98px) {
  .video-inner-content-wrap {
    width: 100%;
  }

  .video-content-wrap {
    flex-direction: column;
  }

  .video-content {
    width: auto;
  }
  .live-katha-video {
    min-height: 490px;
  }
}

@media (max-width: 1024px) {
  .video-content-wrap {
    flex-direction: column;
  }

  .video-inner-content-wrap {
    width: 100%;
  }

  .video-content-wrap {
    flex-direction: column;
  }

  h5 {
    font-size: 30px;
  }

  .content {
    font-size: 26px;
  }
  .live-katha-video {
    min-height: 420px;
  }
}

@media (max-width: 991.98px) {
  h5 {
    font-size: 22px;
  }

  .content {
    font-size: 18px;
  }
  .live-katha-video {
    min-height: 420px;
  }
}

@media (max-width: 767.98px) {
  .video-inner-content-wrap {
    width: 100%;
  }

  .video-inner-content-wrap p {
    font-size: 16px;
  }

  .video-inner-content {
    margin: 30px 0;
    flex-direction: column;
  }

  .video-content-wrap {
    flex-direction: column;
    gap: 40px;
  }

  h5 {
    font-size: 22px;
  }

  .content {
    font-size: 18px;
  }
  .live-katha-video {
    min-height: 340px;
  }
}

@media (max-width: 575.98px) {
  .video-inner-content-wrap {
    width: 100%;
  }

  .video-inner-content-wrap .video {
    height: 250px;
  }

  /* .video-content {
    gap: 18px;
  } */

  .video-content-wrap {
    flex-direction: column;
    gap: 35px;
  }

  .video-inner-content {
    margin: 30px 0;
    flex-direction: column;
  }

  .check-icon {
    /* font-size: 18px; */
    font-size: 16px;
  }

  .icon-wrap {
    /* min-width: 30px;
    height: 30px; */
    min-width: 28px;
    height: 28px;
    margin-right: -3px;
  }

  h5 {
    font-size: 18px !important;
    /* font-size: 16px !important; */
  }

  .content {
    font-size: 16px;
  }

  .live-katha-video {
    min-height: 240px;
  }

  .live-video-content {
    padding: 10px;
  }
}

/* @media (max-width: 401px) and (max-width: 575.99) {
  .live-katha-video {
    min-height: 200px;
  }

  .live-video-content {
    padding: 10px;
  }
} */

@media (min-width: 200px) and (max-width: 398.99px) {
  .live-katha-video {
    min-height: 200px;
  }

  .live-video-content {
    padding: 10px;
  }
}
