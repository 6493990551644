.thankyou-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: 50px 0px 33px 0px;
}

.thankyou {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.thankyou-text {
  color: var(--global-color);
  font-size: 18px;
  font-weight: 600;
}
.thankyou-title {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.thankyou-data {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 600;
}
.download-btn{
  padding: 9px 8px;
  background: var(--text-color);
  border-radius: 4px;
  border: none;
  color: white;
  font-size: 14px;
}

@media (max-width: 575.98px) {
  .thankyou-text {
    font-size: 15px;
  }
  .thankyou-data {
    font-size: 15px;
  }
}

@media (max-width: 1199.98px) {
  .thankyou-title {
    width: 50%;
  }
  .thankyou {
    width: 90%;
    overflow: auto;
  }
}
