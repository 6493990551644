.founder-img {
  /* width: 165px; */
  /* border: 1px solid var(--text-color); */
  /* border-radius: 50%; */
  /* padding: 10px; */
  width: 100%;
  height: 210px;
  margin: 40px 0px 0px 0px;
  transition: box-shadow 0.2s ease-in-out;
  object-fit: contain;
}

.founder-img:hover {
  /* box-shadow: 0px 0px 20px 0px rgba(166, 15, 15, 0.592); */
}
.abouts-inner-section {
}
.about-us-detail-page {
  padding-top: 80px;
}

@media (max-width: 1024px) {
  .founder-img {
    margin: 0 auto;
    display: flex;
    margin-top: 40px;
    /* width: auto; */
  }
}

@media (min-width: 359.99px) and (max-width: 991.98px) {
  .abouts-inner-section {
    margin-top: 80px;
  }
}
