.loader-container-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: white; */
  /* background-color: #635e5e; */
  /* background-color: #0006; */
  background-color: #d9d9d7;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 9999;
  /*  */
  height: 100%;
  width: 100%;
}

.loader-logo {
  width: 100px;
  height: auto;
  animation: zoom-in-out 2s infinite;
  /* animation: zoom-rotate 2s infinite; */
}

@keyframes zoom-in-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* @keyframes zoom-rotate {
    0% {
      transform: scale(1) rotate(0deg);
    }
    50% {
      transform: scale(1.2) rotate(180deg);
    }
    100% {
      transform: scale(1) rotate(360deg);
    }
  } */
