.wallpaper-content {
  text-decoration: none;
  text-align: center;
  color: var(--text-color);
  padding: 10px 10px 10px 10px;
  border: 1px solid #ccc;
  transition: box-shadow 0.3s ease;
  position: relative;
  /* width: 200px; */
}

.wallpaper-content:hover {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.wallpaper-content .search-icon {
  display: none;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 26px;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.wallpaper-img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.wallpaper-img:hover {
  cursor: pointer;
}

.wallpaper-content button {
  font-size: 14px;
  padding: 13px 23px;
  border: 1px solid var(--text-color);
  background-color: var(--text-color);
  color: #fff;
  border-radius: 2px;
  letter-spacing: 0.5px;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
}

.wallpaper-content:hover .search-icon {
  display: block;
  opacity: 1;
  cursor: pointer;
}

.gallery-activities-name {
  padding: 12px 0px 12px 0px;
  font-size: 16px;
}

.gallery-activities-wrap:hover {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.gallery-title {
  padding: 10px 0px 30px 0px;
}

.gallery-title h4 {
  color: #777;
  font-size: 18px;
}

.image-details {
  padding-top: 12px;
  padding: 10px 0px 10px 0px;
}

.image-details .wallpaper-name {
  color: var(--text-color);
  /* font-size: 18px;
  font-weight: 700; */
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.image-details .wallpaper-desc {
  color: #777;
  font-size: 15px;
}
@media (max-width: 1199.98px) {
  .wallpaper-content {
    width: auto;
  }
}

@media (max-width: 991.98px) {
  .wallpaper-content {
    width: auto;
  }
}

@media (max-width: 767.98px) {
  .wallpaper-content {
    width: auto;
  }
}

@media (max-width: 576px) {
  .wallpaper-img {
    height: auto;
  }
}
